.row_margin_f{
    margin: 4% 0%;
}

.text_spacing{
    margin: 2% 0% ;
}

.Function_img{
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 60%;
}

.Functioning_section_visible{
    padding-top: inherit;
    opacity: 1;
    transform: none;
    visibility: visible ;
}

.Function_img4{
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 46%;
}

.paper_block{
    margin-top: 12%;
}

.paper_block2{
    margin-top: 9%;
}

.paper_block3{
    margin-top: 25%;
}


@media only screen and (min-width : 1920px) { 
    
   .paper_large_screens{
       width: fit-content;
   }
}




@media only screen and (max-width : 767px) { 
    
    #functioning_row1, #functioning_row3{
        display: flex;
        flex-direction: column-reverse;
    }

    #functioning_row2, #functioning_row4{
        display: flex;
        flex-direction: column;
    }
    
    .row_margin_f .col-6{
        width: 100%;
    }

    .paper_block, .paper_block2, .paper_block3{
        margin-top: 0%;
    }

    

    #Function_img1{
        margin-top: 4%;
    }

}

