.col-1 {width: 8.33%;}
.col-2 {width: 16.66%;}
.col-3 {width: 25%;}
.col-4 {width: 33.33%;}
.col-5 {width: 41.66%;}
.col-6 {width: 50%;}
.col-7 {width: 58.33%;}
.col-8 {width: 66.66%;}
.col-9 {width: 75%;}
.col-10 {width: 83.33%;}
.col-11 {width: 91.66%;}
.col-12 {width: 100%;}
.col-30 {width: 30%;}
.col-30-divider{width: 5%;} 
.col-4-divider{width: 4%;}
.col-37{width: 37.33%;}

.container {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: 7%;
    margin-left: 10%;
    margin-top: 4%;
}

.row{
    display: flex;
}

.block{
    display: grid;
    margin: 5% 0%;
}

.slideIn1{
    animation-duration: 2s;
    animation-name: slideinfrombottom;
}

.slideIn2{
    animation-duration: 1.5s;
    /* animation-name: slideinfromright; */
}

.slideIn3{
    animation-duration: 1.5s;
    /* animation-name: slideinfromleft; */
}

section{
    opacity: 0;
    transform: translate(0, 50%);
    visibility: hidden;
    transition: opacity 1s ease-out, transform 1s ease-out;
    will-change: opacity, visibility;   
}


.block2{
    display: grid;
    margin: 5% 0%;
}

.flex_container{
    display: flex;
    margin: 4% 0%;
}

.main_title{
    color: var(--color-dark-green);
    font-family: var(--font-raleway-bold);
    font-size: 43px;
    margin-top: 6%;
    width: fit-content;
    padding-top: 2%;
}

.semi_title{
    color: var(--color-dark-green);
    font-family: var(--font-raleway-medium);
    font-size: 24px;  
    white-space: nowrap;
}

.normal_text{
    color: var(--color-dark-green);
    font-family: var(--font-roboto-regular);
    font-size: 21px;
    white-space: nowrap;
}

.small_text{
    color: var(--color-dark-green);
    font-family: var(--font-roboto-regular);
    font-size: 15.5px;
    white-space: nowrap;
}

.main_title_border{
    padding-bottom: 2%;
    padding-right: 3%;
    border-bottom: 8px solid var(--color-dark-green);
}

.paper{
    background-color: var(--color-white);
    box-shadow: 1px 3px 8px 0px var(--color-grey);
    border-radius: 7px;
    padding: 2% 2%;
}

.paper2{
    background-color: var(--color-light-green);
    box-shadow: 1px 3px 8px 0px var(--color-grey);
    border-radius: 7px;
    padding: 1% 9%;
    margin: 2% 2%;
}

.paper3{
    background-color: var(--color-white);
    padding: 2% 2%;
}

.cursor{
    cursor: pointer;
}

.link{
    border: inherit;
    background-color: inherit;
    text-decoration: underline;
    color: var(--color-dark-green);
    font-size: 16px;
    font-style: var(--font-raleway-medium);
    cursor: pointer;
}

.link2{
    border: inherit;
    background-color: inherit;
    text-decoration: underline;
    color: var(--color-dark-green);
    font-size: 18px;
    font-style: var(--font-raleway-bold);
    cursor: pointer;
    margin:2% 1%;
}



@media only screen and (min-width : 1920px) { 
    .small_text{
        font-size: 18px;
    }

    .normal_text{
        font-size: 28px;
    }

    .semi_title{
        font-size: 26px;
    }
    .main_title{
        font-size: 45px;
    }

    .link{
        font-size: 20px;
    }
    
    .link2{
        font-size: 18px;
    }
    .container{
       width: 1400px;
       margin: 0 auto;
    }

    .flex_container{
        margin-top: 4%;
        margin-bottom: 6%;
    }

    /* .block{
        margin: 8% 0%;
    } */

}



@media only screen and (min-width : 1600px) and (max-width : 1919px){ 
    .small_text{
        font-size: 18px;
    }

    .normal_text{
        font-size: 24px;
    }

    .semi_title{
        font-size: 28px;
    }

    .link{
        font-size: 18px;
    }
    
    .link2{
        font-size: 20px;
    }

    /* .block{
        margin: 6% 0%;
    } */
}

@media only screen and (min-width : 1440px) and (max-width : 1599px){
    .small_text{
        font-size: 16.2px;
    }
}


@media only screen and (min-width : 1280px) and (max-width : 1365px){ 
    .semi_title{
        font-size: 22px;
    }

    .small_text{
        font-size: 14px;
    }

    .link2{
        font-size: 17px;
    }

}


@media only screen and (min-width : 1024px) and (max-width : 1279px){ 
    .small_text{
        font-size: 11px;
    }

    .main_title{
        font-size: 28px;
    }

    .normal_text{
        font-size: 16px;
    }

    .semi_title{
        font-size: 16px;
    }

    .link2{
        font-size: 15px;
    }

    .link{
        font-size: 13px;
    }

    .flex_container{
        margin: 6% 0%;
    }

    .ouilink_icon{
        margin-top: 16%;
    }

}

@media only screen and (min-width : 900px) and (max-width : 1023px){ 
    .small_text{
        font-size: 10px;
    }

    .main_title{
        font-size: 24px;
    }

    .normal_text{
        font-size: 15px;
    }

    .semi_title{
        font-size: 14.5px;
    }

    .link2{
        font-size: 13px;
    }

    .link{
        font-size: 11px;
    }

    .main_title_border{
        border-bottom: 4px solid var(--color-dark-green);
    }
    

}

@media only screen and (min-width : 768px) and (max-width : 899px){ 
    .small_text{
        font-size: 8.4px;
    }

    .main_title{
        font-size: 22px;
    }

    .normal_text{
        font-size: 13px;
    }

    .semi_title{
        font-size: 12px;
    }

    .link2{
        font-size: 13px;
    }

    .link{
        font-size: 11px;
    }

    .main_title_border{
        border-bottom: 4px solid var(--color-dark-green);
    }
    

}

@media only screen and (max-width : 767px){ 
    .small_text{
        font-size: 14px;
        white-space: normal;
    }

    .main_title{
        font-size: 20px;
    }

    .normal_text{
        font-size: 16px;
    }

    .semi_title{
        font-size: 18px;
        white-space: normal;
    }

    .link2{
        font-size: 13px;
    }

    .link{
        font-size: 13px;
    }

    .main_title_border{
        border-bottom: 4px solid var(--color-dark-green);
    }

    .paper2{
        width: 100%;
        padding: 3% 9%;
        text-align: center;
        margin: 5% 0%;
    }

    .contact_form div{
        margin-bottom: 0%;
    }
    

}

@media only screen and (max-width : 479px) { 
    .semi_title{
        font-size: 16px;
        white-space: normal;
    }

    .small_text{
        font-size: 12px;
        white-space: normal;
    }
}