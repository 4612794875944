.overlay {
    height: 100%;
    width: 100%;
    position: fixed;
    z-index: 3;
    top: 0;
    left: 0;
    background-color: rgba(176,184,187, 0.8);
    overflow-x: hidden;
    transition: 0.5s;
}

.overlay_empty_zone{
    height: 62%;
    cursor: pointer;
}

.overlay_details_zone{
    height: 38%;
    background-color: var(--color-white);
    opacity: 0.9;
}

.overlay_container{
    padding:2% 7%;
    padding-bottom: 0%;
    height: fit-content;
}

.overlay_title{
    color: var(--color-dark-green);
    font-family: var(--font-roboto-regular);
    font-weight: bold;
    font-size: 16px;
}

.overlay_title2{
    color: var(--color-black);
    font-family: var(--font-roboto-regular);
    font-weight: bold;
    font-size: 16px;
}

.overlay_text{
    margin-top: 2%;
    color: var(--color-dark-green);
    font-family: var(--font-roboto-regular);
    font-size: 14px;
    line-height: 1.3;
}

.align_overlay_link{
    justify-content: flex-end;
}

.overlay_btn{
    background-color: var(--color-light-green);
    color: var(--color-white);
    border: inherit;
    padding: 9px;
    font-size: 16px;
    font-family: var(--font-raleway-medium);
    border-radius: 6px;
    width: 24%;
    font-weight: bold;
    cursor: pointer;
}

.overlay_btn2{
    background-color: var(--color-white);
    color: var(--color-white);
    border: 1px solid var(--color-light-green);
    padding: 9px;
    font-size: 16px;
    color: var(--color-black);
    font-family: var(--font-raleway-medium);
    border-radius: 6px;
    width: 17%;
    font-weight: bold;
    cursor: pointer;
    margin-left:2%;
}

.overlay_btn3{
    background-color: var(--color-light-green);
    color: var(--color-white);
    border: inherit;
    padding: 9px;
    font-size: 16px;
    font-family: var(--font-raleway-medium);
    border-radius: 6px;
    width: 17%;
    margin-left: 2%;
    font-weight: bold;
    cursor: pointer;
}

.switch_row{
    margin: 2% 0%;
    
}

.mobile_switch_row{
    display: none;
}

.mobile_align_overlay_link{
    display: none;
}

@media only screen and (min-width : 1920px) { 
    .overlay_title{
        font-size: 22px;
    }

    .overlay_text{
        font-size: 20px;
    }

    .overlay_btn, .overlay_btn2, .overlay_btn3{
        font-size: 20px;
    }

    .overlay_title2{
        font-size: 20px;
    }

    .overlay_details_zone{
        height: 38%;
    }
    .overlay_empty_zone{
        height: 62%;
    }

    .overlay_container{
        padding: 2% 0%;
        width: 1700px;
        margin: 0% auto;
        padding-bottom: 0%;
    }

}



@media only screen and (min-width : 1440px) and (max-width : 1600px){ 
    .overlay_text{
        font-size: 14.8px;
    }
}
@media only screen and (min-width : 1366px) and (max-width : 1439px){ 
    .overlay_details_zone{
        height: 39%;
    }
    .overlay_empty_zone{
        height: 61%;
    }
}


@media only screen and (min-width : 1280px) and (max-width : 1365px){ 
    .overlay_text{
        font-size: 13px;
    }
    .overlay_title2{
        font-size: 15px;
    }

    .overlay_btn,.overlay_btn2,.overlay_btn3{
        font-size: 15px;
    }

    .overlay_details_zone{
        height: 34%;
    }
    .overlay_empty_zone{
        height: 66%;
    }

    .overlay_btn2{
        padding: 8px;
    }

}

@media only screen and (min-width : 1024px) and (max-width : 1279px){ 
    .overlay_text{
        font-size: 10.5px;
    }
    
    .overlay_title{
        font-size: 13px;
    }

    .overlay_title2{
        font-size: 13px;
    }

    .overlay_btn,.overlay_btn2,.overlay_btn3{
        font-size: 12px;
        padding: 8px;
    }

    .overlay_btn2, .overlay_btn3{
        width: 20%;
    }
    
    .overlay_details_zone{
        height: 34%;
    }
    .overlay_empty_zone{
        height: 66%;
    }
    

}

@media only screen and (min-width : 768px) and (max-width : 1023px){ 
    .overlay_text{
        font-size: 8px;
    }
    
    .overlay_title{
        font-size: 10px;
    }

    .overlay_title2{
        font-size: 11px;
    }

    .overlay_btn,.overlay_btn2,.overlay_btn3{
        font-size: 10px;
        padding: 6px;
    }

    .overlay_btn2, .overlay_btn3{
        width: 20%;
    }
    
    .overlay_details_zone{
        height: 26%;
    }
    .overlay_empty_zone{
        height: 74%;
    }
    

}


@media only screen and (max-width : 767px) { 
    .overlay_text{
        font-size: 12px;
    }
    
    .overlay_title{
        font-size: 14px;
    }

    .overlay_title2{
        font-size: 14px;
    }

    .overlay_btn{
        font-size: 16px;
        padding: 9px;
        width: 100%;
        font-weight: inherit;
        margin-top: 5%;
    }

    .overlay_btn2{
        font-weight: inherit;
        font-size: 14px;
        margin-bottom: 3%;
    }

    .overlay_btn3{
        font-weight: inherit;
    }
    
    .overlay_details_zone{
        height: 60%;
    }
    .overlay_empty_zone{
        height: 40%;
    }

    .overlay_row{
        display: flex;
        flex-direction: column;
    }

    .overlay_row div{
        width: 100%;
        margin: 2% 0%;
    }
    
    .switch_row{
        display: none;
    }

    .mobile_switch_row{
        display: flex;
        margin: 4% 0%;
        justify-content: space-around;
        align-items: center;
    }

    .align_overlay_link{
        display: none;
    }

    .mobile_align_overlay_link{
        display: flex;
    }

    .mobile_align_overlay_link button{
        width: 100%;
    }

}