body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url(/static/media/background_background.e920a8c9.jpg);
  width: 100vw;
  max-width: 100%;
  background-repeat: repeat-y;
}

@font-face {
  font-family: 'Raleway-Bold';
  src: url(/static/media/Raleway-Bold.6cd1ece1.woff2) format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Raleway-Medium';
  src: url(/static/media/Raleway-Medium.cad0ac60.woff2) format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto-LightItalic';
  src: url(/static/media/Roboto-LightItalic.a7ed711d.woff2) format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto-Regular';
  src: url(/static/media/Roboto-Regular.856ef3bd.woff2) format('woff2');
  font-weight: normal;
  font-style: normal;
}



:root {
  --font-raleway-bold: 'Raleway-Bold';
  --font-raleway-medium: 'Raleway-Medium';
  --font-italic-light: 'Roboto-LightItalic';
  --font-roboto-regular: 'Roboto-Regular';
  
  --color-black: #0C0C0C;
  --color-gray: #545454;
  --color-crimson: #F5EFDB;
  --color-grey: #AAAAAA;
  --color-white: #FFFFFF;
  --color-teal: #008080;
  --color-teal-hover: #01a4a4;
  --color-light-green: #55c597;
  --color-light-green-hover: #60dfab;
  --color-green: #188c8c;
  --color-dark-green: #5c6c73;
  --color-lighter-green: #89d5d2;
  --color-lighter-green-hover: #93e3e0;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}

a {
  color: unset;
  text-decoration: none;
}

/* .slide-bottom {
  -webkit-animation: slide-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	animation: slide-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@-webkit-keyframes slide-bottom {
  0% {
    -webkit-transform: translateY(-25%);
            transform: translateY(-25%);
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

@keyframes slide-bottom {
  0% {
    -webkit-transform: translateY(-25%);
            transform: translateY(-25%);
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
} */


@media only screen and (min-width : 1920px){ 
  body{
    background-image: url(/static/media/background_qhd.d71167ca.jpg);
  }
}


@media only screen and (max-width : 767px){ 
  body{
    background-image: url(/static/media/background_mobile.1a228a0b.jpg);
  }
}




@-webkit-keyframes slideinfromright {
  from {
    margin-left: 30%;
  }

  to {
    
  }
}




@keyframes slideinfromright {
  from {
    margin-left: 30%;
  }

  to {
    
  }
}

@-webkit-keyframes slideinfromleft {
  from {
    margin-right: 30%;
  }

  to {
    
  }
}

@keyframes slideinfromleft {
  from {
    margin-right: 30%;
  }

  to {
    
  }
}

@-webkit-keyframes slideinfrombottom {
  from {
    margin-top: 60%;
  }

  to {
    
  }
}

@keyframes slideinfrombottom {
  from {
    margin-top: 60%;
  }

  to {
    
  }
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@-webkit-keyframes fadeOut{
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes fadeOut{
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
.col-1 {width: 8.33%;}
.col-2 {width: 16.66%;}
.col-3 {width: 25%;}
.col-4 {width: 33.33%;}
.col-5 {width: 41.66%;}
.col-6 {width: 50%;}
.col-7 {width: 58.33%;}
.col-8 {width: 66.66%;}
.col-9 {width: 75%;}
.col-10 {width: 83.33%;}
.col-11 {width: 91.66%;}
.col-12 {width: 100%;}
.col-30 {width: 30%;}
.col-30-divider{width: 5%;} 
.col-4-divider{width: 4%;}
.col-37{width: 37.33%;}

.container {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: 7%;
    margin-left: 10%;
    margin-top: 4%;
}

.row{
    display: flex;
}

.block{
    display: grid;
    margin: 5% 0%;
}

.slideIn1{
    -webkit-animation-duration: 2s;
            animation-duration: 2s;
    -webkit-animation-name: slideinfrombottom;
            animation-name: slideinfrombottom;
}

.slideIn2{
    -webkit-animation-duration: 1.5s;
            animation-duration: 1.5s;
    /* animation-name: slideinfromright; */
}

.slideIn3{
    -webkit-animation-duration: 1.5s;
            animation-duration: 1.5s;
    /* animation-name: slideinfromleft; */
}

section{
    opacity: 0;
    -webkit-transform: translate(0, 50%);
            transform: translate(0, 50%);
    visibility: hidden;
    transition: opacity 1s ease-out, -webkit-transform 1s ease-out;
    transition: opacity 1s ease-out, transform 1s ease-out;
    transition: opacity 1s ease-out, transform 1s ease-out, -webkit-transform 1s ease-out;
    will-change: opacity, visibility;   
}


.block2{
    display: grid;
    margin: 5% 0%;
}

.flex_container{
    display: flex;
    margin: 4% 0%;
}

.main_title{
    color: var(--color-dark-green);
    font-family: var(--font-raleway-bold);
    font-size: 43px;
    margin-top: 6%;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding-top: 2%;
}

.semi_title{
    color: var(--color-dark-green);
    font-family: var(--font-raleway-medium);
    font-size: 24px;  
    white-space: nowrap;
}

.normal_text{
    color: var(--color-dark-green);
    font-family: var(--font-roboto-regular);
    font-size: 21px;
    white-space: nowrap;
}

.small_text{
    color: var(--color-dark-green);
    font-family: var(--font-roboto-regular);
    font-size: 15.5px;
    white-space: nowrap;
}

.main_title_border{
    padding-bottom: 2%;
    padding-right: 3%;
    border-bottom: 8px solid var(--color-dark-green);
}

.paper{
    background-color: var(--color-white);
    box-shadow: 1px 3px 8px 0px var(--color-grey);
    border-radius: 7px;
    padding: 2% 2%;
}

.paper2{
    background-color: var(--color-light-green);
    box-shadow: 1px 3px 8px 0px var(--color-grey);
    border-radius: 7px;
    padding: 1% 9%;
    margin: 2% 2%;
}

.paper3{
    background-color: var(--color-white);
    padding: 2% 2%;
}

.cursor{
    cursor: pointer;
}

.link{
    border: inherit;
    background-color: inherit;
    text-decoration: underline;
    color: var(--color-dark-green);
    font-size: 16px;
    font-style: var(--font-raleway-medium);
    cursor: pointer;
}

.link2{
    border: inherit;
    background-color: inherit;
    text-decoration: underline;
    color: var(--color-dark-green);
    font-size: 18px;
    font-style: var(--font-raleway-bold);
    cursor: pointer;
    margin:2% 1%;
}



@media only screen and (min-width : 1920px) { 
    .small_text{
        font-size: 18px;
    }

    .normal_text{
        font-size: 28px;
    }

    .semi_title{
        font-size: 26px;
    }
    .main_title{
        font-size: 45px;
    }

    .link{
        font-size: 20px;
    }
    
    .link2{
        font-size: 18px;
    }
    .container{
       width: 1400px;
       margin: 0 auto;
    }

    .flex_container{
        margin-top: 4%;
        margin-bottom: 6%;
    }

    /* .block{
        margin: 8% 0%;
    } */

}



@media only screen and (min-width : 1600px) and (max-width : 1919px){ 
    .small_text{
        font-size: 18px;
    }

    .normal_text{
        font-size: 24px;
    }

    .semi_title{
        font-size: 28px;
    }

    .link{
        font-size: 18px;
    }
    
    .link2{
        font-size: 20px;
    }

    /* .block{
        margin: 6% 0%;
    } */
}

@media only screen and (min-width : 1440px) and (max-width : 1599px){
    .small_text{
        font-size: 16.2px;
    }
}


@media only screen and (min-width : 1280px) and (max-width : 1365px){ 
    .semi_title{
        font-size: 22px;
    }

    .small_text{
        font-size: 14px;
    }

    .link2{
        font-size: 17px;
    }

}


@media only screen and (min-width : 1024px) and (max-width : 1279px){ 
    .small_text{
        font-size: 11px;
    }

    .main_title{
        font-size: 28px;
    }

    .normal_text{
        font-size: 16px;
    }

    .semi_title{
        font-size: 16px;
    }

    .link2{
        font-size: 15px;
    }

    .link{
        font-size: 13px;
    }

    .flex_container{
        margin: 6% 0%;
    }

    .ouilink_icon{
        margin-top: 16%;
    }

}

@media only screen and (min-width : 900px) and (max-width : 1023px){ 
    .small_text{
        font-size: 10px;
    }

    .main_title{
        font-size: 24px;
    }

    .normal_text{
        font-size: 15px;
    }

    .semi_title{
        font-size: 14.5px;
    }

    .link2{
        font-size: 13px;
    }

    .link{
        font-size: 11px;
    }

    .main_title_border{
        border-bottom: 4px solid var(--color-dark-green);
    }
    

}

@media only screen and (min-width : 768px) and (max-width : 899px){ 
    .small_text{
        font-size: 8.4px;
    }

    .main_title{
        font-size: 22px;
    }

    .normal_text{
        font-size: 13px;
    }

    .semi_title{
        font-size: 12px;
    }

    .link2{
        font-size: 13px;
    }

    .link{
        font-size: 11px;
    }

    .main_title_border{
        border-bottom: 4px solid var(--color-dark-green);
    }
    

}

@media only screen and (max-width : 767px){ 
    .small_text{
        font-size: 14px;
        white-space: normal;
    }

    .main_title{
        font-size: 20px;
    }

    .normal_text{
        font-size: 16px;
    }

    .semi_title{
        font-size: 18px;
        white-space: normal;
    }

    .link2{
        font-size: 13px;
    }

    .link{
        font-size: 13px;
    }

    .main_title_border{
        border-bottom: 4px solid var(--color-dark-green);
    }

    .paper2{
        width: 100%;
        padding: 3% 9%;
        text-align: center;
        margin: 5% 0%;
    }

    .contact_form div{
        margin-bottom: 0%;
    }
    

}

@media only screen and (max-width : 479px) { 
    .semi_title{
        font-size: 16px;
        white-space: normal;
    }

    .small_text{
        font-size: 12px;
        white-space: normal;
    }
}
.sidenav {
    /* height: 100vh; */
    width: 100px;
    top: 0;
    left: 0;
    background-color: var(--color-green);
    position: fixed;
    font-size: 36px;
    display: flex;
    flex-direction: column;
    z-index: 1;
    border-right: 0.5px solid var(--color-teal);
    white-space: nowrap;
    
}
.sidenav:hover .btn_title, .sidenav:hover .LinkTitle, .sidenav:hover .link_title_selected{
    display: block;
    transition: 1s;
}

.sidenav:hover .linksIcons, .sidenav:hover .btn_icons, .sidenav:hover .Last_Link_Icon {
    margin-right: 1rem;
    transition: 1s;
}

.sidenav:hover .last_link img{
    margin-left: 0;
    
}

.sidenav:hover .last_link {
    padding: 0rem 2.5rem;
}

.sidenav:hover .last_link #Eng_off, .sidenav:hover .last_link #Fr_off{
    display: flex;
    transition: 1s;
}

.sidenav:hover .boutons_links a{
    opacity: 1;
    border-radius: 6px;
    padding-right: 0;
    transition: 1.5s;
}

.sidenav:hover{
    transition: 1.5s;
    width: 280px;
}

.sidenav .last_link #Eng_off,.sidenav .last_link #Fr_off{
    display: none;
}
  
.links_container a, .last_link a, .last_link button{
    font-size: 20px;
    transition: 0.3s;
    font-family: var(--font-raleway-medium);
    display: flex;
    align-items: center;
}
  
.links_container a:hover{
    background-color: var(--color-teal-hover);
}

.links_container {
    background-color: var(--color-teal);
}
.links_container_selected{
    background-color: var(--color-white);
}

.links_container_selected a{
    color: var(--color-teal);
}

.links_container_selected a:hover{
    background-color: transparent;
    background-color: initial;
}

.links_container a{
    padding: 1.5rem 1.5rem;
    cursor: pointer;
}

.last_link a{
    padding: 1rem 1.5rem;
}

.last_link button{
    margin: 1rem 1.5rem;
    border: inherit;
    cursor: pointer;
    background-color: inherit;
}


.last_link {
    background-color: var(--color-teal);
    cursor: pointer;
    display: flex;
}


.linksIcons {
    height: 3rem;
    width: 3rem;
    margin-right: 0;
}

.last_link_icon{
    height: 2rem;
    width: 3rem;
    margin-left: auto;
    margin-right: auto;
}

.LinkTitle, .btn_title{
    font-size: 20px;
    display: none;
    transition: 1s;
    color: var(--color-white);
    white-space: nowrap;
}

.link_title_selected{
    font-size: 20px;
    display: none;
    transition: 1s;
    color: var(--color-teal);
}

.boutons_links a{
    font-size: 20px;
    color: var(--color-white);
    transition: 0.3s;
    font-family: var(--font-raleway-medium);
    padding: 0.4rem 0;
    margin: 1rem 1rem;
    padding-left: 1rem;
    display: flex;
    align-items: center;
    cursor: pointer;
    padding-right: 77%;
    opacity: 0.9;
    white-space: nowrap;
    transition: 1s;
}

.boutons_links{
    margin-top: auto;
}

.btn_icons{
    height: 2.2rem;
    width: 2.2rem;
    margin-right: 0;
}

#connect_btn{
    background-color: var(--color-lighter-green);
}

#signup_btn{
    background-color: var(--color-light-green);
}

#signup_btn:hover{
    background-color: var(--color-light-green-hover);
}

#connect_btn:hover{
    background-color: var(--color-lighter-green-hover);
}

@media only screen and (min-width : 1280px) and (max-width : 1365px){ 

    .sidenav{
        width: 96px;
    }
 
    .sidenav:hover{
        width: 280px;
    }
 
 }


@media only screen and (min-width : 1024px) and (max-width : 1279px){ 
   .linksIcons{
    height: 2.5rem;
    width: 2.5rem;
   }

   .last_link_icon{
       width: 2.5rem;
   }

   .btn_icons{
       height: 1.8rem;
       width: 1.8rem;
   }

   .LinkTitle, .btn_title, .link_title_selected{
       font-size: 18px;
   }

   .sidenav{
       width: 90px;
   }

   .sidenav:hover{
       width: 260px;
   }

}



/* .content{
    
} */

.home_icon{
    height: auto;
}

.ouilink_icon{
    margin-top: 24%;
    margin-bottom: 12%;
}


.divider {
    border: 4px solid var(--color-dark-green);
    margin: 6% 0px;
    margin-right: 10px;
}

.home_btn{
    background-color: var(--color-light-green);
    color: var(--color-white);
    border: inherit;
    padding: 9px;
    font-size: 23px;
    font-family: var(--font-raleway-medium);
    border-radius: 6px;
    cursor: pointer;
}

.btn_section{
    margin-top: 10%;
}

.mobile_home_img{
    display: none;
}


@media only screen and (min-width : 2060px) 
{ 
    .ouilink_icon{
        margin-top: 10%;
        width: 94%;
        margin-bottom: 10%;
    }

    .home_icon{
        width: 74%;
    }

    #Home {
        padding-top:4% ;
    }
}


@media only screen and (min-width : 1920px) and (max-width: 2059px ) 
{ 
    .ouilink_icon{
        margin-top: 6%;
        margin-bottom: 8%;
    }

    .home_icon{
        width: 82%;
    }
}


@media only screen and (min-width : 1600px) and (max-width : 1919px)
{ 
    .home_icon{
        width: 84%;
    }

    .ouilink_icon{
        margin-top: 10%;
    }
}


@media only screen and (min-width : 1366px) and (max-width : 1599px)
{ 
    .home_icon{
        width: 84%;
    }

    .ouilink_icon{
        margin-top: 6%;
        margin-bottom: 8%;
    }
}

@media only screen and (min-width : 1280px) and (max-width : 1365px)
{ 
    .home_icon{
        width: 94%;
    }

    .ouilink_icon{
        margin-top: 14%;
        margin-bottom: 10%;
    }
}



@media only screen and (min-width : 1024px) and (max-width : 1279px){ 
    .home_btn{
        font-size: 16px;
    }
}

@media only screen and (min-width : 768px) and (max-width : 1023px){ 
    .home_btn{
        font-size: 13px;
        padding: 7px;
    }

    .divider{
        border: 2px solid var(--color-dark-green);
    }

    .ouilink_icon{
        margin-top: 22%;
    }

}

@media only screen and (max-width : 767px) { 
  .home_icon{
      display: none;
  }
  .flex_container div{
      width: 100%;
  }
  .flex_container{
      flex-direction: column;
      padding-bottom: 10%;
  }
  .flex_container .row .col-2{
      width: 0%;
  }

  .ouilink_icon{
      width: 18rem;
      margin-top: 15%;
  }

  .mobile_home_img{
      display: block;
      margin: 8% 0%;
  }

  .mobile_home_icon{
      width: 14rem;
      display: block;
      margin: 0% auto;
  }

  .btn_section .col-8{
      width: inherit;
  }

  .home_btn{
    font-size: 18px;
    padding: 9px auto;
}

.divider{
    margin-right: 40px;
}


}
.row_margin{
    margin: 6% 0%;
}

.Idee_section_visible{
    padding-top: inherit;
    opacity: 1;
    -webkit-transform: none;
            transform: none;
    visibility: visible ;
}

.Idee_img{
    width: 30%;
    margin-left: auto;
    margin-right: auto;
    display: block;
    margin-bottom: 8%;
}

.center_text{
    text-align: center;
    margin-top: 4%;
}

#Idee_section{
    margin-bottom: 0%;
}

#video_container{
    margin-top: 5%; 
    overflow: hidden;
    position: relative;
}

.video{
    width: 80% !important;
    height: 100% !important;
    margin: auto auto;
}

.playButton{
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    padding: 10px calc(50% - 50px);
    top: 0;
    left: 0;
    display: block;
    position: absolute;
    cursor: pointer;
    
}

.hidePlayButton{
    display: none;
}

.video video {
    position: relative;
    left: 0;
    top: 0;
    -webkit-transform: none;
            transform: none;
}

@media only screen and (max-width : 767px) { 
  .idee_mobile_row{
    display: flex;
    flex-direction: column;
  }

  .idee_mobile_row .col-30{
      width: 100%;
  }

  .idee_mobile_row .paper{
      padding: 4%;
      margin: 0% 2%;
      margin-bottom: 10%;
  }

  .Idee_img{
      width: 25%;
      margin-bottom: 6%;
  }

  .video{
      width: 95%;
  }

  .row_margin{
      margin: 8% 0%;
  }
  .playButton{
    padding: 10px calc(52% - 50px);
  }

}

@media only screen  and (max-width : 480px) { 
    
    .playButton{
      padding: 10px calc(56% - 50px);
    }
  
  }
.row_margin_f{
    margin: 4% 0%;
}

.text_spacing{
    margin: 2% 0% ;
}

.Function_img{
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 60%;
}

.Functioning_section_visible{
    padding-top: inherit;
    opacity: 1;
    -webkit-transform: none;
            transform: none;
    visibility: visible ;
}

.Function_img4{
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 46%;
}

.paper_block{
    margin-top: 12%;
}

.paper_block2{
    margin-top: 9%;
}

.paper_block3{
    margin-top: 25%;
}


@media only screen and (min-width : 1920px) { 
    
   .paper_large_screens{
       width: -webkit-fit-content;
       width: -moz-fit-content;
       width: fit-content;
   }
}




@media only screen and (max-width : 767px) { 
    
    #functioning_row1, #functioning_row3{
        display: flex;
        flex-direction: column-reverse;
    }

    #functioning_row2, #functioning_row4{
        display: flex;
        flex-direction: column;
    }
    
    .row_margin_f .col-6{
        width: 100%;
    }

    .paper_block, .paper_block2, .paper_block3{
        margin-top: 0%;
    }

    

    #Function_img1{
        margin-top: 4%;
    }

}


.Partners_section_visible{
    padding-top: inherit;
    opacity: 1;
    -webkit-transform: none;
            transform: none;
    visibility: visible ;
}

.text_center{
    text-align: center;
}

.partner_types{
    align-items: center;
    justify-content: center;
    padding: 1% 9%;
}

.partners{
    display: block;
    margin-top: 5%;
}

.partner_logos{
    justify-content: center;
    align-items: center;
    margin-top: 3%;
    margin-bottom: 1%;
}

.partner_img{
    margin: 0% 2%;
}

.partner_description, .restorer_description{
    font-weight: bold;
    font-style: italic;
    color: var(--color-dark-green);
    font-family: var(--font-roboto-regular);
    font-size: 18px;
    margin: auto;
}

.restorer_description{
    font-size: 13px;
}

.partner_row_margin{
    margin: 5% 0%;
}

.partner_paper{
    padding: 5% 0%;
    padding-top: 0%;
    background-color: var(--color-white);
    box-shadow: 1px 3px 8px 0px var(--color-grey);
    border-radius: 7px;
    height: 100%;
}

.partner_title_paper{
    background-color: var(--color-white);
    box-shadow: 1px 3px 8px 0px var(--color-grey);
    border-radius: 7px;
    padding: 6% 2%;
}

.partner_paper_text_block{
    padding: 7% 5%;
    padding-bottom: 0%;
}

.partner_btn{
    background-color: var(--color-light-green);
    color: var(--color-white);
    border: inherit;
    padding: 9px;
    font-size: 18px;
    font-family: var(--font-raleway-medium);
    border-radius: 6px;
    margin-top: 7%;
}

.partner_paper_text{
    color: var(--color-white);
}

.partner_text{
    color: var(--color-dark-green);
    font-family: var(--font-roboto-regular);
    font-size: 12.3px;
}

.title_margin{
    margin-top: 1%;
}

.align_link{
    justify-content: center;
    align-items: center;
}

.restorer_btn_section{
    bottom: 0;
}



#coma1{
    margin-bottom: 7%;
}

#coma2{
    margin-top: 7%;
}

.text_divider{
    border: 1px solid;
    margin: 10% 2%;
    margin-bottom: 5%;
    color: var(--color-grey);
}

.partner_text_title{
    font-weight: bold;
    margin-bottom: 2%;
    /* white-space: nowrap; */
}

.partner_list_items{
    margin-bottom: 4%;
    margin-left: 4%;
    white-space: nowrap;
}

.small_title_margin{
    margin-top: 1%;
}

.mobile_partner_logos{
    display: none;
}

#manage_btn, #optimise_btn{
    margin-top: 10%;
}

@media only screen and (min-width : 1920px) 
{ 
    .partner_description{
        font-size: 22px;
    }
    .restorer_description{
        font-size: 17px;
    }
    .partner_text{
        font-size: 16px;
    }
    .partner_btn{
        font-size: 20px;
    }
}


@media only screen and (min-width : 1600px) and (max-width : 1919px)
{ 
    .partner_description{
        font-size: 22px;
    }
    .restorer_description{
        font-size: 16px;
    }
    .partner_text{
        font-size: 15px;
    }
    .partner_btn{
        font-size: 20px;
    }
}

@media only screen and (min-width : 1440px) and (max-width : 1599px){ 
    .partner_text{
        font-size: 13.4px;
    }

    .restorer_description {
        font-size: 14px;
      }

}


@media only screen and (min-width : 1280px) and (max-width : 1365px){ 
    .partner_text{
        font-size: 12px;
    }

    .restorer_description {
        font-size: 12px;
    }

    .partner_btn{
        font-size: 17px;
    }

}

@media only screen and (min-width : 1024px) and (max-width : 1279px){ 
    #EasylisLogo{
        width: 14rem;
    }

    #AwsLogo{
        width: 6rem;
    }

    #BpiLogo{
        width: 12rem;
    }

    #CicLogo{
        width: 8rem;
    }

    #LftLogo{
        width: 7rem;
    }

    #LadditionLogo{
        width: 15rem;
    }

    #Minalogic{
        width: 5rem;
    }

    .restorer_description {
        font-size: 10px;
    }

    .partner_description{
        font-size: 14px;
    }

    .partner_text{
        font-size: 9.5px;
    }

    .partner_btn{
        font-size: 13px;
        padding: 7px;
    }
    

}

@media only screen and (min-width : 900px) and (max-width : 1023px){ 
    #EasylisLogo{
        width: 12rem;
    }

    #AwsLogo{
        width: 4rem;
    }

    #BpiLogo{
        width: 10rem;
    }

    #CicLogo{
        width: 6rem;
    }

    #LftLogo{
        width: 6rem;
    }

    #LadditionLogo{
        width: 12rem;
    }

    #MinalogicLogo{
        width: 4rem;
    }

    .restorer_description {
        font-size: 8.5px;
    }

    .partner_description{
        font-size: 11px;
    }

    .partner_text{
        font-size: 8.3px;
    }

    .partner_btn{
        font-size: 10px;
        padding: 7px;
    }
    
    .comas{
        width: 90%;
    }

    .link2{
        font-size: 12px;
    }

}

@media only screen and (min-width : 768px) and (max-width : 899px){ 
    #EasylisLogo{
        width: 12rem;
    }

    #AwsLogo{
        width: 4rem;
    }

    #BpiLogo{
        width: 10rem;
    }

    #CicLogo{
        width: 6rem;
    }

    #LftLogo{
        width: 6rem;
    }

    #LadditionLogo{
        width: 12rem;
    }

    #MinalogicLogo{
        width: 4rem;
    }

    .restorer_description {
        font-size: 7.3px;
    }

    .partner_description{
        font-size: 11px;
    }

    .partner_text{
        font-size: 7px;
    }

    .partner_btn{
        font-size: 10px;
        padding: 7px;
    }
    
    .comas{
        width: 90%;
    }

    .link2{
        font-size: 12px;
    }

}


@media only screen and (max-width : 767px) { 
    #EasylisLogo{
        width: 10rem;
    }

    #AwsLogo{
        width: 4rem;
    }

    #BpiLogo{
        width: 8rem;
    }

    #CicLogo{
        width: 5rem;
    }

    #LftLogo{
        width: 5rem;
    }

    #LadditionLogo{
        width: 10rem;
        margin-top: 2%;
    }

    #MinalogicLogo{
        width: 3rem;
    }

    .restorer_description {
        font-size: 10px;
    }

    .partner_description{
        font-size: 11px;
    }

    .partner_text{
        font-size: 12px;
    }

    .partner_btn{
        font-size: 14px;
        padding: 9px;
        margin-top: 2%;
    }
    
    .comas{
        width: 90%;
    }

    .link2{
        font-size: 12px;
    }

    .partner_types{
        flex-direction: column;
    }

    .mobile_partner_logos{
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        margin-top: 3%;
        margin-bottom: 1%;
    }

    .partner_logos{
        display: none;
    }

    .mobile_partner_row{
        display: flex;
        flex-direction: column;
    }

    .mobile_partner_row .col-30{
        width: 100%;
        margin-bottom: 7% ;
    }

    .partner_text_title{
        font-size: 13px;
    }

    .partner_list_items{
        font-size: 13px;
    }

    #manage_btn, #optimise_btn{
        margin-top: 0;
        margin-top: initial;
    }

}


@media only screen and (max-width : 479px) { 
    .partner_list_items, .partner_text{
        font-size: 10px;
    }


    
}


.Contact_section_visible{
    opacity: 1;
    -webkit-transform: none;
            transform: none;
    visibility: visible !important;
}

.contact_row_margin{
    margin: 5% 0%;
}

.contact_form{
    margin: 6% 0%;
    margin-bottom: 0%;
}

.form_label{
    display: block;
    color: var(--color-dark-green);
    margin-left: 2%;
    font-family: var(--font-roboto-regular);
    margin-bottom: 2%;
    font-size: 16px;
}

.form_input{
    display: block;
    width: 100%;
    height: 44px;
    font-family: var(--font-roboto-regular);
    font-style: italic;
    font-size: 16px;
    color: var(--color-dark-green);
    margin-bottom: 4%;
    padding-left: 2%;
}

.select_appearance{
    -webkit-appearance: menulist-button;
}

.contact_form2_btn{
    margin-top: 10%;
}

.contact_btn{
    background-color: var(--color-light-green);
    color: var(--color-white);
    border: inherit;
    padding: 9px;
    font-size: 18px;
    font-family: var(--font-raleway-medium);
    border-radius: 6px;
    margin-top: 7%;
}

.msg_select{
    margin-top: 3%;
    width: 90%;
}

.form_textarea{
    margin-top: 2%;
    height: 250px;
    padding-top: 2%;
}

#send_msg{
    margin-top: 1%;
}

@media only screen and (min-width : 1920px)
{ 
    .form_label{
        font-size: 18px;
    }
    .contact_btn{
        font-size: 22px;
    }

    .form_textarea{
        height: 280px;
    }
}


@media only screen and (min-width : 1601px) and (max-width : 1919px)
{ 
    .form_label{
        font-size: 18px;
    }
    .contact_btn{
        font-size: 20px;
    }
}

@media only screen and (min-width : 1280px) and (max-width : 1365px){ 
    .form_label{
        font-size: 15px;
    }
    .form_input{
        font-size: 15px;
    }
    .contact_btn{
        font-size: 16px;
    }
    

}

@media only screen and (min-width : 1024px) and (max-width : 1279px){ 
    .form_label{
        font-size: 14px;
    }
    .form_input{
        font-size: 12px;
        height: 38px;
    }
    .contact_btn{
        font-size: 13px;
    }

    .form_textarea{
        height: 207px;
    }

}

@media only screen and (min-width : 900px) and (max-width : 1023px){ 
    .form_label{
        font-size: 13px;
    }
    .form_input{
        font-size: 10px;
        height: 30px;
    }
    .contact_btn{
        font-size: 11px;
    }

    .form_textarea{
        height: 171px;
    }
}


@media only screen and (min-width : 768px) and (max-width : 899px){ 
    .form_label{
        font-size: 13px;
    }
    .form_input{
        font-size: 10px;
        height: 30px;
    }
    .contact_btn{
        font-size: 11px;
    }

    .form_textarea{
        height: 171px;
    }
    

}

@media only screen and (max-width : 767px) { 
    .form_label{
        font-size: 13px;
    }
    .form_input{
        font-size: 10px;
        height: 30px;
    }
    .contact_btn{
        font-size: 11px;
    }

    .form_textarea{
        height: 171px;
    }
    
    .mobile_contact_row{
        display: flex;
        flex-direction: column;
    }

    .mobile_contact_row div{
        width: 100%;
        margin-bottom: 5%;
    }

    .mobile_contact_form_row{
        display: flex;
        flex-direction: column;
    }

    .mobile_contact_form_row div{
        width: 100%;
        margin-bottom: 0%;
    }

    .msg_select{
        width: 100%;
    }

}
.Infos_section_visible{
    padding-top: inherit;
    opacity: 1;
    -webkit-transform: none;
            transform: none;
    visibility: visible ;
}

.info_text{
    padding-left: 2%;
}

.faq_icons{
    display: block;
    height: 100%;
    width: -webkit-fill-available;
}

.infos_row_margin{
    margin: 6% 0%;
    margin-bottom: 0%;
}

.infos_text_spacing{
    margin: 2% 0%;
    white-space: nowrap;
    line-height: 1.3;
}

.info_paper{
    background-color: var(--color-white);
    box-shadow: 1px 3px 8px 0px var(--color-grey);
    border-radius: 7px;
    padding: 1% 2%;
    margin-bottom: 2%;
}

.info_paper_title{
    margin-top: 8px;
}

.align_link2{
    margin-top: 6%;
    justify-content: center;
    align-items: center;
}

#Infos_section{
    margin-bottom: 5%;
}

.info_icon_img{
    margin: auto 0;
}


@media only screen and (max-width : 767px) { 
    .infos_text_spacing{
        white-space: normal;
    }

    .faq_icons{
        display: none;
    }

    .mobile_info_row{
        display: flex;
        flex-direction: column;
        margin-bottom: 8%;
    }

    .align_link2{
        display: flex;
        flex-direction: column;
        margin: 15% 0%;
    }

    .align_link2 button{
        margin: 5% 0%;
    }

}
.switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 23px;
  }
  
  .switch input { 
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: -3px;
    right: 0;
    bottom: 0;
    border-radius: 34px;
    background-color: #ccc;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 21px;
    width: 21px;
    left: 1px;
    bottom: 1px;
    background-color: white;
    transition: .4s;
    border-radius: 50%;
  }
  
  input:checked + .slider {
    background-color: var(--color-green);
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px var(--color-green);
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(20px);
    transform: translateX(20px);
  }
  
  .switch_margin{
      margin:0% 1%;
      margin-right: 2%;
  }


@media only screen and (min-width : 1280px) and (max-width : 1365px){ 
    .switch{
      height: 21px;
      width: 38px;
    }

    .slider:before{
      height: 19px;
      width: 19px;
    }
}

@media only screen and (min-width : 1024px) and (max-width : 1279px){ 
  .switch {
    height: 19px;
    width: 36px;
  }

  .slider:before{
    height: 17px;
    width: 17px;
  }
}


@media only screen and (min-width : 768px) and (max-width : 1023px){ 
  .switch {
    height: 15px;
    width: 32px;
  }

  .slider:before{
    height: 13px;
    width: 13px;
  }
}

@media only screen and (max-width : 767px){ 
  .switch {
    height: 19px;
    width: 37px;
  }

  .slider:before{
    height: 18px;
    width: 18px;
  }
}
.overlay {
    height: 100%;
    width: 100%;
    position: fixed;
    z-index: 3;
    top: 0;
    left: 0;
    background-color: rgba(176,184,187, 0.8);
    overflow-x: hidden;
    transition: 0.5s;
}

.overlay_empty_zone{
    height: 62%;
    cursor: pointer;
}

.overlay_details_zone{
    height: 38%;
    background-color: var(--color-white);
    opacity: 0.9;
}

.overlay_container{
    padding:2% 7%;
    padding-bottom: 0%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}

.overlay_title{
    color: var(--color-dark-green);
    font-family: var(--font-roboto-regular);
    font-weight: bold;
    font-size: 16px;
}

.overlay_title2{
    color: var(--color-black);
    font-family: var(--font-roboto-regular);
    font-weight: bold;
    font-size: 16px;
}

.overlay_text{
    margin-top: 2%;
    color: var(--color-dark-green);
    font-family: var(--font-roboto-regular);
    font-size: 14px;
    line-height: 1.3;
}

.align_overlay_link{
    justify-content: flex-end;
}

.overlay_btn{
    background-color: var(--color-light-green);
    color: var(--color-white);
    border: inherit;
    padding: 9px;
    font-size: 16px;
    font-family: var(--font-raleway-medium);
    border-radius: 6px;
    width: 24%;
    font-weight: bold;
    cursor: pointer;
}

.overlay_btn2{
    background-color: var(--color-white);
    color: var(--color-white);
    border: 1px solid var(--color-light-green);
    padding: 9px;
    font-size: 16px;
    color: var(--color-black);
    font-family: var(--font-raleway-medium);
    border-radius: 6px;
    width: 17%;
    font-weight: bold;
    cursor: pointer;
    margin-left:2%;
}

.overlay_btn3{
    background-color: var(--color-light-green);
    color: var(--color-white);
    border: inherit;
    padding: 9px;
    font-size: 16px;
    font-family: var(--font-raleway-medium);
    border-radius: 6px;
    width: 17%;
    margin-left: 2%;
    font-weight: bold;
    cursor: pointer;
}

.switch_row{
    margin: 2% 0%;
    
}

.mobile_switch_row{
    display: none;
}

.mobile_align_overlay_link{
    display: none;
}

@media only screen and (min-width : 1920px) { 
    .overlay_title{
        font-size: 22px;
    }

    .overlay_text{
        font-size: 20px;
    }

    .overlay_btn, .overlay_btn2, .overlay_btn3{
        font-size: 20px;
    }

    .overlay_title2{
        font-size: 20px;
    }

    .overlay_details_zone{
        height: 38%;
    }
    .overlay_empty_zone{
        height: 62%;
    }

    .overlay_container{
        padding: 2% 0%;
        width: 1700px;
        margin: 0% auto;
        padding-bottom: 0%;
    }

}



@media only screen and (min-width : 1440px) and (max-width : 1600px){ 
    .overlay_text{
        font-size: 14.8px;
    }
}
@media only screen and (min-width : 1366px) and (max-width : 1439px){ 
    .overlay_details_zone{
        height: 39%;
    }
    .overlay_empty_zone{
        height: 61%;
    }
}


@media only screen and (min-width : 1280px) and (max-width : 1365px){ 
    .overlay_text{
        font-size: 13px;
    }
    .overlay_title2{
        font-size: 15px;
    }

    .overlay_btn,.overlay_btn2,.overlay_btn3{
        font-size: 15px;
    }

    .overlay_details_zone{
        height: 34%;
    }
    .overlay_empty_zone{
        height: 66%;
    }

    .overlay_btn2{
        padding: 8px;
    }

}

@media only screen and (min-width : 1024px) and (max-width : 1279px){ 
    .overlay_text{
        font-size: 10.5px;
    }
    
    .overlay_title{
        font-size: 13px;
    }

    .overlay_title2{
        font-size: 13px;
    }

    .overlay_btn,.overlay_btn2,.overlay_btn3{
        font-size: 12px;
        padding: 8px;
    }

    .overlay_btn2, .overlay_btn3{
        width: 20%;
    }
    
    .overlay_details_zone{
        height: 34%;
    }
    .overlay_empty_zone{
        height: 66%;
    }
    

}

@media only screen and (min-width : 768px) and (max-width : 1023px){ 
    .overlay_text{
        font-size: 8px;
    }
    
    .overlay_title{
        font-size: 10px;
    }

    .overlay_title2{
        font-size: 11px;
    }

    .overlay_btn,.overlay_btn2,.overlay_btn3{
        font-size: 10px;
        padding: 6px;
    }

    .overlay_btn2, .overlay_btn3{
        width: 20%;
    }
    
    .overlay_details_zone{
        height: 26%;
    }
    .overlay_empty_zone{
        height: 74%;
    }
    

}


@media only screen and (max-width : 767px) { 
    .overlay_text{
        font-size: 12px;
    }
    
    .overlay_title{
        font-size: 14px;
    }

    .overlay_title2{
        font-size: 14px;
    }

    .overlay_btn{
        font-size: 16px;
        padding: 9px;
        width: 100%;
        font-weight: inherit;
        margin-top: 5%;
    }

    .overlay_btn2{
        font-weight: inherit;
        font-size: 14px;
        margin-bottom: 3%;
    }

    .overlay_btn3{
        font-weight: inherit;
    }
    
    .overlay_details_zone{
        height: 60%;
    }
    .overlay_empty_zone{
        height: 40%;
    }

    .overlay_row{
        display: flex;
        flex-direction: column;
    }

    .overlay_row div{
        width: 100%;
        margin: 2% 0%;
    }
    
    .switch_row{
        display: none;
    }

    .mobile_switch_row{
        display: flex;
        margin: 4% 0%;
        justify-content: space-around;
        align-items: center;
    }

    .align_overlay_link{
        display: none;
    }

    .mobile_align_overlay_link{
        display: flex;
    }

    .mobile_align_overlay_link button{
        width: 100%;
    }

}
.mobileSideNav{
    height: 100vh;
    width: 30px;
    top: 0;
    left: 0;
    background-color: var(--color-green);
    position: fixed;
    font-size: 36px;
    display: flex;
    flex-direction: column;
    z-index: 1;
    border-right: 0.5px solid var(--color-teal);
}

.MobileMenu{
    background-color: rgba(176,184,187, 0.7);
    width: 100vw;
}

.FadeOutMobileMenu{
    background-color: rgba(176,184,187, 0.7);
    width: 100vw;
    -webkit-animation-duration: 1.3s;
            animation-duration: 1.3s;
    -webkit-animation-name: fadeOut;
            animation-name: fadeOut;
}

.arrow{
    color: white;
    font-size: 20px;
    padding: 0% 4%;
    justify-content: center;
    align-items: center;
    height: 100vh;
    display: flex;
    cursor: pointer;
}


.mobile_boutons_links{
    margin-top: auto;
    padding-right: 0;
}

.mobile_boutons_links a{
    font-size: 20px;
    color: var(--color-white);
    transition: 0.3s;
    font-family: var(--font-raleway-medium);
    padding: 0.4rem 0;
    margin: 1rem 1rem;
    padding-left: 1rem;
    display: flex;
    border-radius: 6px;
    align-items: center;
    cursor: pointer;
    padding-right: 77%;
    white-space: nowrap;
    
}

.MobileLinkTitle, .mobile_btn_title{
    font-size: 16px;
    display: block;
    transition: 1s;
    color: var(--color-white);
}

.mobile_link_title_selected{
    font-size: 16px;
    transition: 1s;
    color: var(--color-teal);
}

.mobile_links_container_selected{
    background-color: var(--color-white);
    display: block;
}

.mobile_links_container_selected a{
    color: var(--color-teal);
}

.MobileLinksIcons{
    height: 2rem;
    width: 2rem;
    margin-right: 0;
    margin-right: 1rem;
}


.mobile_last_link{
    background-color: var(--color-teal);
    cursor: pointer;
    display: flex;
    padding: 0rem 2rem;
}

.mobile_last_link button{
    margin: 1rem 1.5rem;
    border: inherit;
    cursor: pointer;
    background-color: inherit;
}

.mobile_last_link_icon{
    height: 2rem;
    width: 3rem;
    margin-left: auto;
    margin-right: auto;
}

.mobile_btn_icons{
    height: 1.6rem;
    width: 1.6rem;
    margin-right: 1.2rem;
}

.displayedMenu{
    display: flex;
    flex-direction: column;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    background-color: var(--color-green);
    -webkit-animation-duration: 1.3s;
            animation-duration: 1.3s;
    -webkit-animation-name: fadeIn;
            animation-name: fadeIn;
}

.FadeOutDisplayedMenu{
    display: flex;
    flex-direction: column;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    background-color: var(--color-green);
    -webkit-animation-duration: 1.3s;
            animation-duration: 1.3s;
    -webkit-animation-name: fadeOut;
            animation-name: fadeOut;
}
.Toast {
    height: 100%;
    width: 100%;
    position: fixed;
    z-index: 3;
    top: 0;
    left: 0;
    background-color: rgba(176,184,187, 0.8);
    overflow-x: hidden;
    transition: 0.5s;
    display: flex;
    justify-content: center;
    align-items: center;
}


@media only screen and (max-width : 767px) { 
   #toast_text{
       font-size: 10px;
   }
  
  }
