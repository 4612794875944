.row_margin{
    margin: 6% 0%;
}

.Idee_section_visible{
    padding-top: inherit;
    opacity: 1;
    transform: none;
    visibility: visible ;
}

.Idee_img{
    width: 30%;
    margin-left: auto;
    margin-right: auto;
    display: block;
    margin-bottom: 8%;
}

.center_text{
    text-align: center;
    margin-top: 4%;
}

#Idee_section{
    margin-bottom: 0%;
}

#video_container{
    margin-top: 5%; 
    overflow: hidden;
    position: relative;
}

.video{
    width: 80% !important;
    height: 100% !important;
    margin: auto auto;
}

.playButton{
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    padding: 10px calc(50% - 50px);
    top: 0;
    left: 0;
    display: block;
    position: absolute;
    cursor: pointer;
    
}

.hidePlayButton{
    display: none;
}

.video video {
    position: relative;
    left: 0;
    top: 0;
    transform: none;
}

@media only screen and (max-width : 767px) { 
  .idee_mobile_row{
    display: flex;
    flex-direction: column;
  }

  .idee_mobile_row .col-30{
      width: 100%;
  }

  .idee_mobile_row .paper{
      padding: 4%;
      margin: 0% 2%;
      margin-bottom: 10%;
  }

  .Idee_img{
      width: 25%;
      margin-bottom: 6%;
  }

  .video{
      width: 95%;
  }

  .row_margin{
      margin: 8% 0%;
  }
  .playButton{
    padding: 10px calc(52% - 50px);
  }

}

@media only screen  and (max-width : 480px) { 
    
    .playButton{
      padding: 10px calc(56% - 50px);
    }
  
  }