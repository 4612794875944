.Toast {
    height: 100%;
    width: 100%;
    position: fixed;
    z-index: 3;
    top: 0;
    left: 0;
    background-color: rgba(176,184,187, 0.8);
    overflow-x: hidden;
    transition: 0.5s;
    display: flex;
    justify-content: center;
    align-items: center;
}


@media only screen and (max-width : 767px) { 
   #toast_text{
       font-size: 10px;
   }
  
  }