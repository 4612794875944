.switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 23px;
  }
  
  .switch input { 
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: -3px;
    right: 0;
    bottom: 0;
    border-radius: 34px;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 21px;
    width: 21px;
    left: 1px;
    bottom: 1px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 50%;
  }
  
  input:checked + .slider {
    background-color: var(--color-green);
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px var(--color-green);
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(20px);
    -ms-transform: translateX(20px);
    transform: translateX(20px);
  }
  
  .switch_margin{
      margin:0% 1%;
      margin-right: 2%;
  }


@media only screen and (min-width : 1280px) and (max-width : 1365px){ 
    .switch{
      height: 21px;
      width: 38px;
    }

    .slider:before{
      height: 19px;
      width: 19px;
    }
}

@media only screen and (min-width : 1024px) and (max-width : 1279px){ 
  .switch {
    height: 19px;
    width: 36px;
  }

  .slider:before{
    height: 17px;
    width: 17px;
  }
}


@media only screen and (min-width : 768px) and (max-width : 1023px){ 
  .switch {
    height: 15px;
    width: 32px;
  }

  .slider:before{
    height: 13px;
    width: 13px;
  }
}

@media only screen and (max-width : 767px){ 
  .switch {
    height: 19px;
    width: 37px;
  }

  .slider:before{
    height: 18px;
    width: 18px;
  }
}