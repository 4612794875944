

.Contact_section_visible{
    opacity: 1;
    transform: none;
    visibility: visible !important;
}

.contact_row_margin{
    margin: 5% 0%;
}

.contact_form{
    margin: 6% 0%;
    margin-bottom: 0%;
}

.form_label{
    display: block;
    color: var(--color-dark-green);
    margin-left: 2%;
    font-family: var(--font-roboto-regular);
    margin-bottom: 2%;
    font-size: 16px;
}

.form_input{
    display: block;
    width: 100%;
    height: 44px;
    font-family: var(--font-roboto-regular);
    font-style: italic;
    font-size: 16px;
    color: var(--color-dark-green);
    margin-bottom: 4%;
    padding-left: 2%;
}

.select_appearance{
    -webkit-appearance: menulist-button;
}

.contact_form2_btn{
    margin-top: 10%;
}

.contact_btn{
    background-color: var(--color-light-green);
    color: var(--color-white);
    border: inherit;
    padding: 9px;
    font-size: 18px;
    font-family: var(--font-raleway-medium);
    border-radius: 6px;
    margin-top: 7%;
}

.msg_select{
    margin-top: 3%;
    width: 90%;
}

.form_textarea{
    margin-top: 2%;
    height: 250px;
    padding-top: 2%;
}

#send_msg{
    margin-top: 1%;
}

@media only screen and (min-width : 1920px)
{ 
    .form_label{
        font-size: 18px;
    }
    .contact_btn{
        font-size: 22px;
    }

    .form_textarea{
        height: 280px;
    }
}


@media only screen and (min-width : 1601px) and (max-width : 1919px)
{ 
    .form_label{
        font-size: 18px;
    }
    .contact_btn{
        font-size: 20px;
    }
}

@media only screen and (min-width : 1280px) and (max-width : 1365px){ 
    .form_label{
        font-size: 15px;
    }
    .form_input{
        font-size: 15px;
    }
    .contact_btn{
        font-size: 16px;
    }
    

}

@media only screen and (min-width : 1024px) and (max-width : 1279px){ 
    .form_label{
        font-size: 14px;
    }
    .form_input{
        font-size: 12px;
        height: 38px;
    }
    .contact_btn{
        font-size: 13px;
    }

    .form_textarea{
        height: 207px;
    }

}

@media only screen and (min-width : 900px) and (max-width : 1023px){ 
    .form_label{
        font-size: 13px;
    }
    .form_input{
        font-size: 10px;
        height: 30px;
    }
    .contact_btn{
        font-size: 11px;
    }

    .form_textarea{
        height: 171px;
    }
}


@media only screen and (min-width : 768px) and (max-width : 899px){ 
    .form_label{
        font-size: 13px;
    }
    .form_input{
        font-size: 10px;
        height: 30px;
    }
    .contact_btn{
        font-size: 11px;
    }

    .form_textarea{
        height: 171px;
    }
    

}

@media only screen and (max-width : 767px) { 
    .form_label{
        font-size: 13px;
    }
    .form_input{
        font-size: 10px;
        height: 30px;
    }
    .contact_btn{
        font-size: 11px;
    }

    .form_textarea{
        height: 171px;
    }
    
    .mobile_contact_row{
        display: flex;
        flex-direction: column;
    }

    .mobile_contact_row div{
        width: 100%;
        margin-bottom: 5%;
    }

    .mobile_contact_form_row{
        display: flex;
        flex-direction: column;
    }

    .mobile_contact_form_row div{
        width: 100%;
        margin-bottom: 0%;
    }

    .msg_select{
        width: 100%;
    }

}