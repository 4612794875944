.Infos_section_visible{
    padding-top: inherit;
    opacity: 1;
    transform: none;
    visibility: visible ;
}

.info_text{
    padding-left: 2%;
}

.faq_icons{
    display: block;
    height: 100%;
    width: -webkit-fill-available;
}

.infos_row_margin{
    margin: 6% 0%;
    margin-bottom: 0%;
}

.infos_text_spacing{
    margin: 2% 0%;
    white-space: nowrap;
    line-height: 1.3;
}

.info_paper{
    background-color: var(--color-white);
    box-shadow: 1px 3px 8px 0px var(--color-grey);
    border-radius: 7px;
    padding: 1% 2%;
    margin-bottom: 2%;
}

.info_paper_title{
    margin-top: 8px;
}

.align_link2{
    margin-top: 6%;
    justify-content: center;
    align-items: center;
}

#Infos_section{
    margin-bottom: 5%;
}

.info_icon_img{
    margin: auto 0;
}


@media only screen and (max-width : 767px) { 
    .infos_text_spacing{
        white-space: normal;
    }

    .faq_icons{
        display: none;
    }

    .mobile_info_row{
        display: flex;
        flex-direction: column;
        margin-bottom: 8%;
    }

    .align_link2{
        display: flex;
        flex-direction: column;
        margin: 15% 0%;
    }

    .align_link2 button{
        margin: 5% 0%;
    }

}