body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url('./images/background_background.jpg');
  width: 100vw;
  max-width: 100%;
  background-repeat: repeat-y;
}

@font-face {
  font-family: 'Raleway-Bold';
  src: url('./fonts/Raleway-Bold.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Raleway-Medium';
  src: url('./fonts/Raleway-Medium.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto-LightItalic';
  src: url('./fonts/Roboto-LightItalic.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto-Regular';
  src: url('./fonts/Roboto-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}



:root {
  --font-raleway-bold: 'Raleway-Bold';
  --font-raleway-medium: 'Raleway-Medium';
  --font-italic-light: 'Roboto-LightItalic';
  --font-roboto-regular: 'Roboto-Regular';
  
  --color-black: #0C0C0C;
  --color-gray: #545454;
  --color-crimson: #F5EFDB;
  --color-grey: #AAAAAA;
  --color-white: #FFFFFF;
  --color-teal: #008080;
  --color-teal-hover: #01a4a4;
  --color-light-green: #55c597;
  --color-light-green-hover: #60dfab;
  --color-green: #188c8c;
  --color-dark-green: #5c6c73;
  --color-lighter-green: #89d5d2;
  --color-lighter-green-hover: #93e3e0;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}

a {
  color: unset;
  text-decoration: none;
}

/* .slide-bottom {
  -webkit-animation: slide-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	animation: slide-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@-webkit-keyframes slide-bottom {
  0% {
    -webkit-transform: translateY(-25%);
            transform: translateY(-25%);
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

@keyframes slide-bottom {
  0% {
    -webkit-transform: translateY(-25%);
            transform: translateY(-25%);
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
} */


@media only screen and (min-width : 1920px){ 
  body{
    background-image: url('./images/background_qhd.jpg');
  }
}


@media only screen and (max-width : 767px){ 
  body{
    background-image: url('./images/background_mobile.jpg');
  }
}




@keyframes slideinfromright {
  from {
    margin-left: 30%;
  }

  to {
    
  }
}

@keyframes slideinfromleft {
  from {
    margin-right: 30%;
  }

  to {
    
  }
}

@keyframes slideinfrombottom {
  from {
    margin-top: 60%;
  }

  to {
    
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeOut{
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}