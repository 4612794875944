/* .content{
    
} */

.home_icon{
    height: auto;
}

.ouilink_icon{
    margin-top: 24%;
    margin-bottom: 12%;
}


.divider {
    border: 4px solid var(--color-dark-green);
    margin: 6% 0px;
    margin-right: 10px;
}

.home_btn{
    background-color: var(--color-light-green);
    color: var(--color-white);
    border: inherit;
    padding: 9px;
    font-size: 23px;
    font-family: var(--font-raleway-medium);
    border-radius: 6px;
    cursor: pointer;
}

.btn_section{
    margin-top: 10%;
}

.mobile_home_img{
    display: none;
}


@media only screen and (min-width : 2060px) 
{ 
    .ouilink_icon{
        margin-top: 10%;
        width: 94%;
        margin-bottom: 10%;
    }

    .home_icon{
        width: 74%;
    }

    #Home {
        padding-top:4% ;
    }
}


@media only screen and (min-width : 1920px) and (max-width: 2059px ) 
{ 
    .ouilink_icon{
        margin-top: 6%;
        margin-bottom: 8%;
    }

    .home_icon{
        width: 82%;
    }
}


@media only screen and (min-width : 1600px) and (max-width : 1919px)
{ 
    .home_icon{
        width: 84%;
    }

    .ouilink_icon{
        margin-top: 10%;
    }
}


@media only screen and (min-width : 1366px) and (max-width : 1599px)
{ 
    .home_icon{
        width: 84%;
    }

    .ouilink_icon{
        margin-top: 6%;
        margin-bottom: 8%;
    }
}

@media only screen and (min-width : 1280px) and (max-width : 1365px)
{ 
    .home_icon{
        width: 94%;
    }

    .ouilink_icon{
        margin-top: 14%;
        margin-bottom: 10%;
    }
}



@media only screen and (min-width : 1024px) and (max-width : 1279px){ 
    .home_btn{
        font-size: 16px;
    }
}

@media only screen and (min-width : 768px) and (max-width : 1023px){ 
    .home_btn{
        font-size: 13px;
        padding: 7px;
    }

    .divider{
        border: 2px solid var(--color-dark-green);
    }

    .ouilink_icon{
        margin-top: 22%;
    }

}

@media only screen and (max-width : 767px) { 
  .home_icon{
      display: none;
  }
  .flex_container div{
      width: 100%;
  }
  .flex_container{
      flex-direction: column;
      padding-bottom: 10%;
  }
  .flex_container .row .col-2{
      width: 0%;
  }

  .ouilink_icon{
      width: 18rem;
      margin-top: 15%;
  }

  .mobile_home_img{
      display: block;
      margin: 8% 0%;
  }

  .mobile_home_icon{
      width: 14rem;
      display: block;
      margin: 0% auto;
  }

  .btn_section .col-8{
      width: inherit;
  }

  .home_btn{
    font-size: 18px;
    padding: 9px auto;
}

.divider{
    margin-right: 40px;
}


}