.sidenav {
    /* height: 100vh; */
    width: 100px;
    top: 0;
    left: 0;
    background-color: var(--color-green);
    position: fixed;
    font-size: 36px;
    display: flex;
    flex-direction: column;
    z-index: 1;
    border-right: 0.5px solid var(--color-teal);
    white-space: nowrap;
    
}
.sidenav:hover .btn_title, .sidenav:hover .LinkTitle, .sidenav:hover .link_title_selected{
    display: block;
    transition: 1s;
}

.sidenav:hover .linksIcons, .sidenav:hover .btn_icons, .sidenav:hover .Last_Link_Icon {
    margin-right: 1rem;
    transition: 1s;
}

.sidenav:hover .last_link img{
    margin-left: 0;
    
}

.sidenav:hover .last_link {
    padding: 0rem 2.5rem;
}

.sidenav:hover .last_link #Eng_off, .sidenav:hover .last_link #Fr_off{
    display: flex;
    transition: 1s;
}

.sidenav:hover .boutons_links a{
    opacity: 1;
    border-radius: 6px;
    padding-right: 0;
    transition: 1.5s;
}

.sidenav:hover{
    transition: 1.5s;
    width: 280px;
}

.sidenav .last_link #Eng_off,.sidenav .last_link #Fr_off{
    display: none;
}
  
.links_container a, .last_link a, .last_link button{
    font-size: 20px;
    transition: 0.3s;
    font-family: var(--font-raleway-medium);
    display: flex;
    align-items: center;
}
  
.links_container a:hover{
    background-color: var(--color-teal-hover);
}

.links_container {
    background-color: var(--color-teal);
}
.links_container_selected{
    background-color: var(--color-white);
}

.links_container_selected a{
    color: var(--color-teal);
}

.links_container_selected a:hover{
    background-color: initial;
}

.links_container a{
    padding: 1.5rem 1.5rem;
    cursor: pointer;
}

.last_link a{
    padding: 1rem 1.5rem;
}

.last_link button{
    margin: 1rem 1.5rem;
    border: inherit;
    cursor: pointer;
    background-color: inherit;
}


.last_link {
    background-color: var(--color-teal);
    cursor: pointer;
    display: flex;
}


.linksIcons {
    height: 3rem;
    width: 3rem;
    margin-right: 0;
}

.last_link_icon{
    height: 2rem;
    width: 3rem;
    margin-left: auto;
    margin-right: auto;
}

.LinkTitle, .btn_title{
    font-size: 20px;
    display: none;
    transition: 1s;
    color: var(--color-white);
    white-space: nowrap;
}

.link_title_selected{
    font-size: 20px;
    display: none;
    transition: 1s;
    color: var(--color-teal);
}

.boutons_links a{
    font-size: 20px;
    color: var(--color-white);
    transition: 0.3s;
    font-family: var(--font-raleway-medium);
    padding: 0.4rem 0;
    margin: 1rem 1rem;
    padding-left: 1rem;
    display: flex;
    align-items: center;
    cursor: pointer;
    padding-right: 77%;
    opacity: 0.9;
    white-space: nowrap;
    transition: 1s;
}

.boutons_links{
    margin-top: auto;
}

.btn_icons{
    height: 2.2rem;
    width: 2.2rem;
    margin-right: 0;
}

#connect_btn{
    background-color: var(--color-lighter-green);
}

#signup_btn{
    background-color: var(--color-light-green);
}

#signup_btn:hover{
    background-color: var(--color-light-green-hover);
}

#connect_btn:hover{
    background-color: var(--color-lighter-green-hover);
}

@media only screen and (min-width : 1280px) and (max-width : 1365px){ 

    .sidenav{
        width: 96px;
    }
 
    .sidenav:hover{
        width: 280px;
    }
 
 }


@media only screen and (min-width : 1024px) and (max-width : 1279px){ 
   .linksIcons{
    height: 2.5rem;
    width: 2.5rem;
   }

   .last_link_icon{
       width: 2.5rem;
   }

   .btn_icons{
       height: 1.8rem;
       width: 1.8rem;
   }

   .LinkTitle, .btn_title, .link_title_selected{
       font-size: 18px;
   }

   .sidenav{
       width: 90px;
   }

   .sidenav:hover{
       width: 260px;
   }

}


