.mobileSideNav{
    height: 100vh;
    width: 30px;
    top: 0;
    left: 0;
    background-color: var(--color-green);
    position: fixed;
    font-size: 36px;
    display: flex;
    flex-direction: column;
    z-index: 1;
    border-right: 0.5px solid var(--color-teal);
}

.MobileMenu{
    background-color: rgba(176,184,187, 0.7);
    width: 100vw;
}

.FadeOutMobileMenu{
    background-color: rgba(176,184,187, 0.7);
    width: 100vw;
    animation-duration: 1.3s;
    animation-name: fadeOut;
}

.arrow{
    color: white;
    font-size: 20px;
    padding: 0% 4%;
    justify-content: center;
    align-items: center;
    height: 100vh;
    display: flex;
    cursor: pointer;
}


.mobile_boutons_links{
    margin-top: auto;
    padding-right: 0;
}

.mobile_boutons_links a{
    font-size: 20px;
    color: var(--color-white);
    transition: 0.3s;
    font-family: var(--font-raleway-medium);
    padding: 0.4rem 0;
    margin: 1rem 1rem;
    padding-left: 1rem;
    display: flex;
    border-radius: 6px;
    align-items: center;
    cursor: pointer;
    padding-right: 77%;
    white-space: nowrap;
    
}

.MobileLinkTitle, .mobile_btn_title{
    font-size: 16px;
    display: block;
    transition: 1s;
    color: var(--color-white);
}

.mobile_link_title_selected{
    font-size: 16px;
    transition: 1s;
    color: var(--color-teal);
}

.mobile_links_container_selected{
    background-color: var(--color-white);
    display: block;
}

.mobile_links_container_selected a{
    color: var(--color-teal);
}

.MobileLinksIcons{
    height: 2rem;
    width: 2rem;
    margin-right: 0;
    margin-right: 1rem;
}


.mobile_last_link{
    background-color: var(--color-teal);
    cursor: pointer;
    display: flex;
    padding: 0rem 2rem;
}

.mobile_last_link button{
    margin: 1rem 1.5rem;
    border: inherit;
    cursor: pointer;
    background-color: inherit;
}

.mobile_last_link_icon{
    height: 2rem;
    width: 3rem;
    margin-left: auto;
    margin-right: auto;
}

.mobile_btn_icons{
    height: 1.6rem;
    width: 1.6rem;
    margin-right: 1.2rem;
}

.displayedMenu{
    display: flex;
    flex-direction: column;
    width: fit-content;
    background-color: var(--color-green);
    animation-duration: 1.3s;
    animation-name: fadeIn;
}

.FadeOutDisplayedMenu{
    display: flex;
    flex-direction: column;
    width: fit-content;
    background-color: var(--color-green);
    animation-duration: 1.3s;
    animation-name: fadeOut;
}