.Partners_section_visible{
    padding-top: inherit;
    opacity: 1;
    transform: none;
    visibility: visible ;
}

.text_center{
    text-align: center;
}

.partner_types{
    align-items: center;
    justify-content: center;
    padding: 1% 9%;
}

.partners{
    display: block;
    margin-top: 5%;
}

.partner_logos{
    justify-content: center;
    align-items: center;
    margin-top: 3%;
    margin-bottom: 1%;
}

.partner_img{
    margin: 0% 2%;
}

.partner_description, .restorer_description{
    font-weight: bold;
    font-style: italic;
    color: var(--color-dark-green);
    font-family: var(--font-roboto-regular);
    font-size: 18px;
    margin: auto;
}

.restorer_description{
    font-size: 13px;
}

.partner_row_margin{
    margin: 5% 0%;
}

.partner_paper{
    padding: 5% 0%;
    padding-top: 0%;
    background-color: var(--color-white);
    box-shadow: 1px 3px 8px 0px var(--color-grey);
    border-radius: 7px;
    height: 100%;
}

.partner_title_paper{
    background-color: var(--color-white);
    box-shadow: 1px 3px 8px 0px var(--color-grey);
    border-radius: 7px;
    padding: 6% 2%;
}

.partner_paper_text_block{
    padding: 7% 5%;
    padding-bottom: 0%;
}

.partner_btn{
    background-color: var(--color-light-green);
    color: var(--color-white);
    border: inherit;
    padding: 9px;
    font-size: 18px;
    font-family: var(--font-raleway-medium);
    border-radius: 6px;
    margin-top: 7%;
}

.partner_paper_text{
    color: var(--color-white);
}

.partner_text{
    color: var(--color-dark-green);
    font-family: var(--font-roboto-regular);
    font-size: 12.3px;
}

.title_margin{
    margin-top: 1%;
}

.align_link{
    justify-content: center;
    align-items: center;
}

.restorer_btn_section{
    bottom: 0;
}



#coma1{
    margin-bottom: 7%;
}

#coma2{
    margin-top: 7%;
}

.text_divider{
    border: 1px solid;
    margin: 10% 2%;
    margin-bottom: 5%;
    color: var(--color-grey);
}

.partner_text_title{
    font-weight: bold;
    margin-bottom: 2%;
    /* white-space: nowrap; */
}

.partner_list_items{
    margin-bottom: 4%;
    margin-left: 4%;
    white-space: nowrap;
}

.small_title_margin{
    margin-top: 1%;
}

.mobile_partner_logos{
    display: none;
}

#manage_btn, #optimise_btn{
    margin-top: 10%;
}

@media only screen and (min-width : 1920px) 
{ 
    .partner_description{
        font-size: 22px;
    }
    .restorer_description{
        font-size: 17px;
    }
    .partner_text{
        font-size: 16px;
    }
    .partner_btn{
        font-size: 20px;
    }
}


@media only screen and (min-width : 1600px) and (max-width : 1919px)
{ 
    .partner_description{
        font-size: 22px;
    }
    .restorer_description{
        font-size: 16px;
    }
    .partner_text{
        font-size: 15px;
    }
    .partner_btn{
        font-size: 20px;
    }
}

@media only screen and (min-width : 1440px) and (max-width : 1599px){ 
    .partner_text{
        font-size: 13.4px;
    }

    .restorer_description {
        font-size: 14px;
      }

}


@media only screen and (min-width : 1280px) and (max-width : 1365px){ 
    .partner_text{
        font-size: 12px;
    }

    .restorer_description {
        font-size: 12px;
    }

    .partner_btn{
        font-size: 17px;
    }

}

@media only screen and (min-width : 1024px) and (max-width : 1279px){ 
    #EasylisLogo{
        width: 14rem;
    }

    #AwsLogo{
        width: 6rem;
    }

    #BpiLogo{
        width: 12rem;
    }

    #CicLogo{
        width: 8rem;
    }

    #LftLogo{
        width: 7rem;
    }

    #LadditionLogo{
        width: 15rem;
    }

    #Minalogic{
        width: 5rem;
    }

    .restorer_description {
        font-size: 10px;
    }

    .partner_description{
        font-size: 14px;
    }

    .partner_text{
        font-size: 9.5px;
    }

    .partner_btn{
        font-size: 13px;
        padding: 7px;
    }
    

}

@media only screen and (min-width : 900px) and (max-width : 1023px){ 
    #EasylisLogo{
        width: 12rem;
    }

    #AwsLogo{
        width: 4rem;
    }

    #BpiLogo{
        width: 10rem;
    }

    #CicLogo{
        width: 6rem;
    }

    #LftLogo{
        width: 6rem;
    }

    #LadditionLogo{
        width: 12rem;
    }

    #MinalogicLogo{
        width: 4rem;
    }

    .restorer_description {
        font-size: 8.5px;
    }

    .partner_description{
        font-size: 11px;
    }

    .partner_text{
        font-size: 8.3px;
    }

    .partner_btn{
        font-size: 10px;
        padding: 7px;
    }
    
    .comas{
        width: 90%;
    }

    .link2{
        font-size: 12px;
    }

}

@media only screen and (min-width : 768px) and (max-width : 899px){ 
    #EasylisLogo{
        width: 12rem;
    }

    #AwsLogo{
        width: 4rem;
    }

    #BpiLogo{
        width: 10rem;
    }

    #CicLogo{
        width: 6rem;
    }

    #LftLogo{
        width: 6rem;
    }

    #LadditionLogo{
        width: 12rem;
    }

    #MinalogicLogo{
        width: 4rem;
    }

    .restorer_description {
        font-size: 7.3px;
    }

    .partner_description{
        font-size: 11px;
    }

    .partner_text{
        font-size: 7px;
    }

    .partner_btn{
        font-size: 10px;
        padding: 7px;
    }
    
    .comas{
        width: 90%;
    }

    .link2{
        font-size: 12px;
    }

}


@media only screen and (max-width : 767px) { 
    #EasylisLogo{
        width: 10rem;
    }

    #AwsLogo{
        width: 4rem;
    }

    #BpiLogo{
        width: 8rem;
    }

    #CicLogo{
        width: 5rem;
    }

    #LftLogo{
        width: 5rem;
    }

    #LadditionLogo{
        width: 10rem;
        margin-top: 2%;
    }

    #MinalogicLogo{
        width: 3rem;
    }

    .restorer_description {
        font-size: 10px;
    }

    .partner_description{
        font-size: 11px;
    }

    .partner_text{
        font-size: 12px;
    }

    .partner_btn{
        font-size: 14px;
        padding: 9px;
        margin-top: 2%;
    }
    
    .comas{
        width: 90%;
    }

    .link2{
        font-size: 12px;
    }

    .partner_types{
        flex-direction: column;
    }

    .mobile_partner_logos{
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        margin-top: 3%;
        margin-bottom: 1%;
    }

    .partner_logos{
        display: none;
    }

    .mobile_partner_row{
        display: flex;
        flex-direction: column;
    }

    .mobile_partner_row .col-30{
        width: 100%;
        margin-bottom: 7% ;
    }

    .partner_text_title{
        font-size: 13px;
    }

    .partner_list_items{
        font-size: 13px;
    }

    #manage_btn, #optimise_btn{
        margin-top: initial;
    }

}


@media only screen and (max-width : 479px) { 
    .partner_list_items, .partner_text{
        font-size: 10px;
    }


    
}